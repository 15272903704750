.char {
    color: #FAFAFA;
    font-size: 3em;
    font-weight: 100;
    cursor: pointer;
    margin: 0.2em;
    padding: 0.5em;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

@media only screen and (max-width: 600px) {
    .char {
        margin: 0.3em;
        padding: 0.3em;
    }
}

.char:hover {
    -webkit-box-shadow: 0px 0px 0px 8px #FAFAFA; 
    box-shadow: 0px 0px 0px 8px #FAFAFA;
}

.char__gray {
    opacity: 0.3;
}
