* {
    transition: all 500ms ease;
    /* No tap color */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.app {
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
    user-select: none;
    flex: 1;
}

.app__link {
    text-decoration: none;
    color: #FAFAFA;
    padding: 16px;
    display: block;
    text-align: center;
    font-weight: bold;
}

h1 {
    color: #FAFAFA;
    text-align: center;
}

.app {
    background: url(./noise-black.png);
}

.app-hodnoceni {
    background: url(./noise-green.png);
}

.app-emoji {
    background: url(./noise-blue.png);
}
