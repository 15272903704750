.footer {
    text-align: center;
    margin: 16px;
}

.footer__link {
    text-decoration: none;
    color: #FAFAFA;
}

.footer__cookies {
    color: #FAFAFA;
    font-size: 70%;
    margin: 5px;
    opacity: 0.5;
}